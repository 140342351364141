import { Box, Heading, Icon, Text } from '@hometime-ui/core'
import { MdCheckCircle } from 'react-icons/md'
import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

export default function BookDemoForm({ userInfo }) {
  const isBrowser = () => typeof window !== 'undefined'

  useEffect(() => {
    if (!userInfo.state) {
      navigate('/contact-us/')
    } else {
      isBrowser && localStorage.setItem('calendarStatus', true)
    }
  }, [])

  return (
    <>
      <Box
        textAlign="center"
        d="inline-block"
        maxW="544px"
        mx="auto"
        mt={{ base: 10, md: 14 }}
        mb="6"
      >
        <Icon as={MdCheckCircle} color="teal.200" w="14" h="14" mb="7" />
        <Heading level="3" mb="2">
          Thanks for you enquiry
        </Heading>
        <Text color="gray.700">
          A member of the team will get in touch with you shortly.
        </Text>
      </Box>
    </>
  )
}
