import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Subheading,
  VStack,
} from '@hometime-ui/core'
import { MdCheckCircle } from 'react-icons/md'
import React from 'react'
import signupGraphic from '~/images/signup_graphic.svg'

export default function ContactUsBlurb(props) {
  const renderBlurbBulletPoint = (strKey) => (
    <HStack spacing="1">
      <Icon as={MdCheckCircle} w="4" h="4" color="teal.200" />
      <Subheading size="xs">{strKey}</Subheading>
    </HStack>
  )

  return (
    <Flex flexDirection="column" position="relative" bg="gray.200" {...props}>
      <Box flex={1} py={{ base: 8, lg: 14 }} pr={{ base: 5, lg: 14 }} h="100%">
        <Heading level="2" color="gray.800">
          Ready to supercharge your short term rental business?
        </Heading>
        <VStack spacing="4" align="flex-start" pt="6">
          {renderBlurbBulletPoint('Automated guest messages')}
          {renderBlurbBulletPoint('Automated cleaning tasks')}
          {renderBlurbBulletPoint('Financial tools & monthly statements')}
          {renderBlurbBulletPoint('Multi-calendar')}
          {renderBlurbBulletPoint('Property & client database')}
          {renderBlurbBulletPoint('Business services')}
        </VStack>
      </Box>
      <Image
        w="100%"
        maxW={{ base: '500px', lg: 'none' }}
        mx="auto"
        src={signupGraphic}
      />
    </Flex>
  )
}
